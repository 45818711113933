import * as React from "react";

interface NavbarProps {}

interface NavbarState {}

export default class Navbar extends React.Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props);
    this.state = {};
  }

  iamActive(href: string) {
    let url = window.location.href;
    return url.indexOf(href) !== -1;
  }

  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className={`nav-item ${this.iamActive("/") ? "active" : ""}`}>
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>

              <li
                className={`nav-item ${
                  this.iamActive("/services") ? "active" : ""
                }`}
              >
                <a className="nav-link" href="/services">
                  Services
                </a>
              </li>

              <li
                className={`nav-item ${
                  this.iamActive("/contact-us") ? "active" : ""
                }`}
              >
                <a className="nav-link" href="/contact-us">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}
