/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import { Helmet } from "react-helmet";

export default class ContactusPage extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8"></meta>
          <title>Contact us</title>
          <meta
            name="description"
            content="Feel free to contact us for an oblication free quote, we are here to help. We can Canberra based builders, our opening hours are Monday to Friday: 9:00am to 6:00pm."
          />
        </Helmet>
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Contact us</h1>
                <div className="h1Divider"></div>
                <br />
                <p>Give us a call or email us today for your FREE quote.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-white contactus">
          <div className="container text-center">
            <div className="row pt-5 mb-5">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href="tel:+61 412 628 450"
                      className="btn btn-sq-lg btn-dark"
                    >
                      <i className="fas fa-phone-square-alt fa-5x mb-2 mt-2"></i>
                      <br />
                      Call us
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="mailto:info@PJtracks.com.au"
                      className="btn btn-sq-lg btn-dark"
                    >
                      <i className="fas fa-envelope fa-5x mb-2 mt-2"></i>
                      <br />
                      Email us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12 mt-2">
                <div className="card text-left">
                  <div className="card-body">
                    <h5 className="card-title text-left">Contact us</h5>
                    <hr />

                    <ul className="fa-ul">
                      <li>
                        <i className="fa-li fas fa-phone-square-alt"></i>
                        <a style={{ color: "#666" }} href="tel:+61 491 371 396">
                          +61 491 371 396
                        </a>
                      </li>
                      <li>
                        <i className="fa-li fas fa-envelope"></i>{" "}
                        <span>info@PJtracks.com.au</span>
                      </li>
                      <li>
                        <i className="fa-li fas fa-map-marker-alt"></i>
                        <span>Chisholm ACT 2905</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
