import React from "react";

export interface BannerControlProps {}

interface BannerControlState {}

export default class BannerControl extends React.Component<
  BannerControlProps,
  BannerControlState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div
          id="carouselControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <a href="/contact-us" className="carousel-item active">
              <img className="d-block w-100" src="/images/banner1.jpg" alt="" />
            </a>
            <a href="/contact-us" className="carousel-item">
              <img className="d-block w-100" src="/images/banner2.jpg" alt="" />
            </a>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    );
  }
}
