/* eslint-disable react/jsx-no-target-blank */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundPage from "./Pages/notfound";
import NavBar from "./components/navbar";
import HomePage from "./Pages/home";

import ReactGA from "react-ga";
import ContactusPage from "./Pages/contactus";
import ServicesPage from "./Pages/services";

export interface LayoutProps {}

export interface LayoutState {}

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.initialiseReactGA();
  }

  initialiseReactGA() {
    if (process.env.REACT_APP_GA) {
      ReactGA.initialize(process.env.REACT_APP_GA);
      ReactGA.pageview(window.location.pathname + window.location.search);

      ReactGA.ga("create", process.env.REACT_APP_GA, "auto");
      ReactGA.ga("require", "eventTracker");
      ReactGA.ga("require", "outboundLinkTracker");
      ReactGA.ga("require", "urlChangeTracker");
      ReactGA.ga("send", "pageview");

      $("a").click(function () {
        let eventName = $(this).text();
        let url = $(this).attr("href");
        ReactGA.event({
          category: "Links",
          action: url,
          label: eventName,
        });
      });
      $("button").click(function () {
        ReactGA.event({
          category: "Button clicks",
          action: window.location.pathname,
          label: $(this).text(),
        });
      });

      $(document).on("change", "select", function () {
        ReactGA.event({
          category: "Dropdown clicks",
          action: window.location.pathname,
          label: $(this).val().toString(),
        });
      });
    }
  }

  render() {
    return (
      <>
        <div className="holder">
          <div className="wrapper">
            <div className="theme-white header">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <div className="row banner">
                      <div className="col-3">
                        <a href="/" className="logo">
                          <img
                            className=""
                            src="/images/logo.png"
                            alt="PJtracks.com.au"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mt-3 text-right">
                    <a
                      className="ml-2"
                      href="tel:+61 412 628 450"
                      title="+61 412 628 450"
                    >
                      <i className="fas fa-phone-square-alt fa-2x"></i>
                    </a>
                    <a
                      className="ml-2"
                      href="mailto:info@PJtracks.com.au"
                      title="info@PJtracks.com.au"
                    >
                      <i className="fas fa-envelope-square fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="theme-menu">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <NavBar />
                  </div>
                </div>
              </div>
            </div>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/services" component={ServicesPage} />
              <Route exact path="/contact-us" component={ContactusPage} />
              <Route component={() => <NotFoundPage />} />
            </Switch>

            <div className="theme theme-footer">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2>Info@PJtracks.com.au</h2>
                    <div className="h1Divider"></div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <h3>General</h3>
                    <ul>
                      <li>
                        <a href="/services">Services</a>
                      </li>

                      <li>
                        <a href="/contact-us">Contact us</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <h3>Contact us</h3>
                    <ul className="fa-ul pl-3">
                      <li>
                        <i className="fa-li fas fa-phone-square-alt"></i>{" "}
                        <a href="tel:+61 491 371 396">+61 491 371 396</a>
                      </li>
                      <li>
                        <i className="fa-li fas fa-envelope"></i>{" "}
                        <a href="mailto:info@PJtracks.com.au">
                          info@PJtracks.com.au
                        </a>
                      </li>
                      <li>
                        <i className="fa-li fas fa-map-marker-alt"></i>
                        <span>Chisholm ACT 2905</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light spacer"></div>
        <div className="theme theme-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-center text-md-left">
                2020 <i className="fas fa-copyright"></i> Copyright
                PJtracks.com.au. All rights Reserved
              </div>
              <div className="col-md-4 powered-by text-center text-md-right">
                <label>Powered by </label>
                <a href="https://acader.com.au" target="_blank">
                  <img
                    src="/images/acader_logo.png"
                    alt="Acader pty ltd"
                    className="img-fluid ml-2"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Layout;
