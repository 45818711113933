import * as React from "react";

export default class NotFoundPage extends React.Component {
    componentDidMount() {
        document.title = "Not Found";
    }

    render() {
        return (
            <>
                <div className='theme-white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 text-center p-5'>
                                <h1>
                                    <i className='fas fa-wrench mr-2'></i>404
                                    Page not found
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
