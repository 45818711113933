import * as React from "react";
import { Helmet } from "react-helmet";
import BannerControl from "../components/banner";

export default class HomePage extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8"></meta>
          <title>Welcome to Peter Wilson Installations</title>
          <meta
            name="description"
            content="Welcome to Peter Wilson Installations, your specialist in Curtain and Blind Installations. With over 30 years experience in business in Canberra Peter can GUARANTEE his work."
          />
        </Helmet>
        <BannerControl />
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Welcome to Peter Wilson Installations</h1>
                <div className="h1Divider"></div>
                <br />
                <p>Your specialist in Curtain and Blind Installations!</p>
                <p>
                  With over 52 years experience in business in Canberra Peter
                  can GUARANTEE his work.
                </p>
                <p>
                  We also do have an advisory service for window furnishings to
                  help customers who are unsure on what to put on their window
                  and there is a cost of $65.00 total for that service.
                </p>
                <button
                  className="btn btn-light"
                  onClick={() => (window.location.href = "/contact-us")}
                >
                  Get a hassle free quote now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="theme theme-grey">
          <div className="container">
            <div className="row home">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-left">Our Work</h5>
                    <hr />

                    <ul className="fa-ul">
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        Specializing in Curtain and Blind Installation
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        Bay Windows
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        Curved windows up to 4 Bends
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        Roman blinds
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        Decorative poles and rods
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        Canberra large range of curtain tracks
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-left">Why Choose Us?</h5>
                    <hr />

                    <ul className="fa-ul">
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        We give FREE measures &amp; quotes
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        We come to you with Professional friendly advice with
                        your curtains and tracks
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        We give 10 years guarantee on our made to measure tracks
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        We have done work for Canberra’s leading stores for the
                        past 20 years
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        We have traveled as far as the snowy mountains and
                        Sydney to provide service
                      </li>
                      <li>
                        <i className="fa-li fas fa-check text-success"></i>
                        We deliver quality work, on time.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-banner">
          <img src="/images/home-hero.jpg" alt="" />
        </div>
        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Canberra Blinds</h2>
                <p>
                  Blinds in Canberra serve an essential purpose. They are used
                  by homeowners and commercial property owners alike, and apart
                  from adding a bit more aesthetic appeal to your place, these
                  blinds can also control the flow of sunlight into your house.
                  However, most people don't really know where they can buy high
                  quality Canberra blinds. At Peter Wilson Installations, we
                  have got you covered. We offer an extensive range of
                  high-quality blinds in Canberra. We follow an aggressive
                  pricing strategy to make sure that all of our products are
                  affordably priced.
                </p>
                <p>
                  We understand that most people don't really know much about
                  where to buy decent blinds in Canberra. There's where we come
                  in. By offering a variety of different types of blinds in
                  Canberra, all under one roof, we provide a one-stop solution
                  to our esteemed customers. All of the Canberra blinds that we
                  offer are made from high quality materials and we provide a
                  guarantee with every new purchase. Here are some of the many
                  different kinds of blinds that we provide at our store.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="theme theme-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>Roller blinds</h2>
                <p>
                  Our roller blinds in Canberra have become tremendously
                  popular. One of the main reasons why so many people prefer
                  buying roller blinds in Canberra is because they are so easy
                  to use and maintain. The blinds use a rolling mechanism to
                  move up and down. They are extremely easy to maintain due to
                  the simple design of the rolling mechanism and also provide
                  ample light control.
                </p>
              </div>
              <div className="col-md-6">
                <h2>Roman blinds</h2>
                <p>
                  Roman blinds in Canberra are also available at Peter Wilson
                  Installations. If you want blinds that not only look good, but
                  also offer practical benefits, you should definitely go for
                  these. Our roman blinds in Canberra feature larger slats, with
                  a folding pattern for each blind. They are also available in
                  numerous different colours and designs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="theme theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>Vertical Blinds</h2>
                <p>
                  Vertical blinds in Canberra are ideally used in offices.
                  However, we also provide window blinds in Canberra that are
                  customised depending upon the requirements of the client. The
                  vertical blinds in Canberra that we offer are made from high
                  quality materials and will last you for years to come!
                </p>
              </div>
              <div className="col-md-6">
                <h2>Venetian Blinds</h2>
                <p>
                  Venetian blinds in Canberra are the most common blinds that
                  you will find in almost every home. If you want such window
                  blinds in Canberra, just give us a call. The reason why
                  venetian blinds are so popular is because they require
                  virtually no maintenance whatsoever. On top of that, these
                  blinds are also very easy to operate. There's a plastic lever
                  on the side that you can twist to set the angle of the blinds.
                </p>
                <p>
                  Venetian blinds in Canberra give you maximum freedom in
                  determining the amount of sunlight that you want to allow into
                  house. These blinds are also available in a multitude of
                  different patterns and designs. You can choose from stylish
                  venetian blinds that create a proper image when you close
                  them, or you can choose from simple coloured blinds as well.
                  There's really no shortage of choice available at Peter Wilson
                  Installations, so visit us today!
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
