import * as React from "react";
import { Helmet } from "react-helmet";

export default class ServicesPage extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8"></meta>
          <title>Services provided by PJtracks.com.au</title>
          <meta
            name="description"
            content="Style line curtain tracks offer you a platter of features and benefits. Beautiful designed, yet strong and ridge curtain tracks maintain high quality construction that will deliver you a lifetime of worry-free operation whilst supporting your curtains."
          />
        </Helmet>
        <div className="theme theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Our Services</h1>
                <div className="h1Divider"></div>
                <br />
                <p>
                  STYLELINE MADE TO MEASURE CURTAIN TRACKS Quality Aluminium
                  tracks preferred by Decorators
                </p>
                <p>
                  Style line curtain tracks offer you a platter of features and
                  benefits. Beautiful designed, yet strong and ridge curtain
                  tracks maintain high quality construction that will deliver
                  you a lifetime of worry-free operation whilst supporting your
                  curtains.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="theme theme-white services">
          <div className="container">
            <div className="row services">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-left">Features Include</h5>
                    <hr />

                    <ul className="fa-ul">
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        The superior gliding performance and great range of
                        colors to suit the changing market
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Our plastic gliders and end caps are protected with UV
                        coating, which contributes to their longer life
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Available in both Hand and Cord Draw Tracks
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        All tracks come with a 5 year guarantee and are heavy
                        duty powder coated aluminium
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-left">
                      Our Wide Range Include
                    </h5>
                    <hr />

                    <ul className="fa-ul">
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Decorator Metal Rods Sets
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Black and Satin Rods
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Stainless Steel sets
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Wood Poles
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Motorised Curtain Tracks
                      </li>
                      <li>
                        <i className="fa-li fas fa-angle-double-right"></i>
                        Brassware, Hooks holders and accessories
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
